import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./pages/home/Home";
import StockMainPage from "./features/stock/pages/main/StockMainPage";
import { Provider } from "react-redux";
import { store } from "./app/store";
import AddStock from "./features/stock/pages/new/AddStock";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/stock">
              <Route index element={<StockMainPage />}></Route>
              <Route path="new" element={<AddStock />}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;

