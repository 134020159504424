import { Button, TextField } from "@mui/material";
import Sidebar from "../../../../components/sidebar/Sidebar";
import "./addstock.scss";
import { useState } from "react";
import { useAddStockMutation } from "../../stockApiSlice";

const AddStock = () => {

    const [productVBNCode, setProductVBNCode] = useState(0);
    const [productDescription, setProductDescription] = useState("");
    const [growerInternalCode, setGrowerInternalCode] = useState("");
    const [growerGLNCode, setGrowerGLNCode] = useState("");
    const [growerName, setGrowerName] = useState("");


    const productVBNCodeChanged = (e) => setProductVBNCode(e.target.value);
    const productDescriptionChanged = (e) => setProductDescription(e.target.value);
    const growerInternalCodeChanged = (e) => setGrowerInternalCode(e.target.value);
    const growerGLNCodeChanged = (e) => setGrowerGLNCode(e.target.value);
    const growerNameChanged = (e) => setGrowerName(e.target.value);

    const [addStock, result] = useAddStockMutation();

    const formSubmitBtnClicked = async (e) => {
        e.preventDefault();
        const stockObject = {
            product: {
                name: productDescription,
                vbnCode: productVBNCode,
                internalCode: "AVA"
            },
            productAttributes: [
                {
                    code: "S20",
                    desciption: "Minimum steellengte",
                    valueCode: "060",
                    valueDescription: "60 cm"
                },
                {
                    code: "S05",
                    desciption: "Rijpheidsstadium",
                    valueCode: "033",
                    valueDescription: "3-3"
                },
                {
                    code: "L11",
                    desciption: "Aantal stelen per bos",
                    valueCode: "010",
                    valueDescription: "10"
                },
                {
                    code: "S62",
                    desciption: "Land van Herkomst",
                    valueCode: "NL",
                    valueDescription: "Nederland"
                },
                {
                    code: "S98",
                    desciption: "Kwaliteitsgroep",
                    valueCode: "A1",
                    valueDescription: "A1"
                }
            ],
            manufacturer: {
                glnCode: growerGLNCode,
                auctionNumbers: [
                    {
                        auctionCode: 6,
                        auctionName: "Plantion",
                        auctionNumber: 56254
                    }
                ],
                name: growerName,
                internalCode: growerInternalCode
            },
            pictures: [
                {
                    url: "https://advisorbeeldbank.blob.core.windows.net/foto240/f1b341bc-c500-4514-afc7-301784507ba4.jpg"
                }
            ],
            quantities: {
                qtyUnits: 10,
                qtyPerUnit: 100,
                qtySold: 100,
                qtyTrash: 200,
                unitsPerLayer: 9,
                layersPerTrolley: 3
            },
            prices: {
                pricePerUnit: 0.25,
                minimumOrderQuantity: 100
            },
            package: {
                vbnCode: "895",
                internalCode: "895",
                description: "Fc577+kraag 45cm(Fc685)"
            },
            trolley: {
                vbnCode: 1,
                internalCode: "STW",
                description: "Stapelwagen"
            }
        }

        await addStock(stockObject);
    }

    return (
        <div className="addStock">
            <Sidebar />
            <div className="addStockContent">
                <div className="addStockContentHeader">
                    Add Stock
                </div>
                <div className="addStockForm">
                    <form onSubmit={formSubmitBtnClicked}>
                        <div className="grower">
                            <div className="growerInternalCode">
                                <p>Internal Code:</p>
                                <TextField size="small" onChange={growerInternalCodeChanged}></TextField>
                            </div>
                            <div className="growerGLNCode">
                                <p>GLN Code:</p>
                                <TextField size="small" onChange={growerGLNCodeChanged}></TextField>
                            </div>
                            <div className="growerName">
                                <p>Grower Name:</p>
                                <TextField size="small" onChange={growerNameChanged}></TextField>
                            </div>
                        </div>
                        <div className="product">
                            <div className="productVBNCode">
                                <p>VBN Code:</p>
                                <TextField size="small" onChange={productVBNCodeChanged}></TextField>
                            </div>
                            <div className="productDescription">
                                <p>Product description:</p>
                                <TextField size="small" onChange={productDescriptionChanged}></TextField>
                            </div>
                        </div>
                        <Button variant="contained" type="submit">Submit</Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddStock;