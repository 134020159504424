import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const baseURL = process.env.NODE_ENV === 'production'
    ? "https://moltoolkit.flowermsg.com/api/v1"
    : "http://localhost:3500/api/v1";

const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    credentials: 'same-origin'
});

export const apiSlice = createApi({
    baseQuery,
    tagTypes: ['Stock'],
    endpoints: builder => ({})
})

