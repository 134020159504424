import { apiSlice } from "../../api/apiSlice";

export const stockApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStock: builder.query({
            query: () => '/grower-erp/stock',
            providesTags: (result, error, arg) => {
                return result
                    ? [...result.map(({ id }) => ({ type: 'Stock', id })), 'Stock']
                    : ['Stock']
            }
        }),
        addStock: builder.mutation({
            query: (stockObject) => ({
                url: '/stock',
                method: 'POST',
                body: stockObject
            }),
            invalidatesTags: ['Stock']
        })
    })
})

export const {
    useGetStockQuery,
    useAddStockMutation
} = stockApiSlice;