import { Link } from "react-router-dom";
import "./home.scss";
import Sidebar from "../../components/sidebar/Sidebar";

const Home = () => {
    return (
        <div className="home">
            <Sidebar />
            <div className="homeContent">
                <div className="top">
                    Top
                </div>
                <div className="bottom">
                    Bottom
                </div>
            </div>
        </div>
    )
}

export default Home;