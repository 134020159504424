import { Link } from "react-router-dom";
import "./sidebar.scss";

const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="top">
                Top
            </div>
            <div className="center">
                Center
                <div className="stock">
                    <Link to="/stock">Stock</Link>
                </div>
            </div>
            <div className="bottom">
                Bottom
            </div>
        </div>
    )
}

export default Sidebar;