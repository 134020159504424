import { DataGrid } from "@mui/x-data-grid"
import { useGetStockQuery } from "../../stockApiSlice";
import { stockDataTableColumns } from "./stockDataTableColumns";
import "./stockdatatable.scss";

const StockDataTable = () => {
    const stock = useGetStockQuery()
    console.log(stock);


    return (
        <div className="stockDataTable">
            {stock.isSuccess && stock.data.map((stockItem) => {
                return <p key={stockItem.id}>{stockItem.description}</p>
            })}
            {/* <DataGrid
                rows={stock.isSuccess ? stock.data : []}
                columns={stockDataTableColumns}
                rowHeight={70}
            /> */}
        </div>
    )
}

export default StockDataTable;