import { Button } from "@mui/material";
import Sidebar from "../../../../components/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import StockDataTable from "../../components/datatable/StockDataTable";
import "./stockmainpage.scss";

const StockMainPage = () => {
    const navigate = useNavigate();

    const addStockBtnClicked = (e) => {
        navigate("new")
    }

    return (
        <div className="stockMainPage">
            <Sidebar />
            <div className="stockContent">
                <div className="stockHeader">
                    <p>Stock Main Page</p>
                    <Button variant="contained" onClick={addStockBtnClicked}>Add Stock</Button>
                </div>
                <StockDataTable />
            </div>
        </div>
    )
}

export default StockMainPage;